import { css } from '@emotion/react'
import type { Note, NoteAuthor } from '@orus.eu/backend/src/views/notes-view'
import { TechnicalError } from '@orus.eu/error'
import { Button, Text, colorTokens, spacing } from '@orus.eu/pharaoh'
import { useCallback, useState, type FormEvent, type ReactElement } from 'react'
import { assert } from '../../../lib/errors'
import { formatDateTime } from '../../../lib/format'
import { useSession } from '../../../lib/session'
import { oneMinute, useCurrentTimestamp } from '../../../lib/use-current-timestamp'
import { validateRequiredField } from '../../../lib/validation'
import { ValidatedTextField } from '../../molecules/validated-text-field'

export type NotesBlockProps = {
  notes: Note[]
  onNoteAdded: (content: string) => void
  onNoteDeleted: (note: Note) => void
  onNoteUpdated: (noteId: string, newContent: string) => void
}

export function NotesBlock({ notes, onNoteAdded, onNoteDeleted, onNoteUpdated }: NotesBlockProps): ReactElement {
  return (
    <div
      css={css`
        flex: 1;
        width: 100%;
      `}
    >
      <NotesListBlock notes={notes} onNoteDeleted={onNoteDeleted} onNoteUpdated={onNoteUpdated} />
      <AddNoteBlock onNoteAdded={onNoteAdded} />
    </div>
  )
}

export type NotesListBlockProps = {
  notes: Note[]
  onNoteDeleted: (note: Note) => void
  onNoteUpdated: (noteId: string, newContent: string) => void
}

function NotesListBlock({ notes, onNoteDeleted, onNoteUpdated }: NotesListBlockProps): ReactElement {
  const nonEmptyNotes = notes.filter((note) => note.currentContent !== null)
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[50]};
      `}
    >
      {nonEmptyNotes.length > 0 ? (
        nonEmptyNotes.map((note) => (
          <NoteBlock key={note.noteId} note={note} onNoteDeleted={onNoteDeleted} onNoteUpdated={onNoteUpdated} />
        ))
      ) : (
        <Text variant="body2">Il n&apos;y a pas encore de notes ici</Text>
      )}
    </div>
  )
}

type AddNoteBlockProps = {
  onNoteAdded: (content: string) => void
}

function AddNoteBlock({ onNoteAdded }: AddNoteBlockProps): ReactElement {
  const [formVisible, setFormVisible] = useState(false)

  const onSave = useCallback(
    (content: string) => {
      setFormVisible(false)
      onNoteAdded(content)
    },
    [onNoteAdded],
  )

  const onCancel = useCallback(() => setFormVisible(false), [])

  const handleAddNoteClick = useCallback(() => setFormVisible(true), [])

  return (
    <div
      css={css`
        margin-top: ${spacing[50]};
      `}
    >
      {formVisible ? (
        <AddNoteForm onCancel={onCancel} onSave={onSave} />
      ) : (
        <Button variant="secondary" size="small" onClick={handleAddNoteClick} icon="plus-solid">
          Ajouter une note
        </Button>
      )}
    </div>
  )
}

type AddNoteFormProps = {
  onCancel: () => void
  onSave: (content: string) => void
}

function AddNoteForm({ onCancel, onSave }: AddNoteFormProps): ReactElement {
  const session = useSession()
  const [content, setContent] = useState<string | null>(null)

  const submitEnabled = !!content

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      if (!submitEnabled) {
        return
      }
      onSave(content)
    },
    [content, onSave, submitEnabled],
  )

  assert(session.user && 'email' in session.user, 'This feature is for orus agents only, and they all have an email')

  const author: NoteAuthor = {
    userId: session.user.id,
    displayName: session.user.email,
  }

  return (
    <form onSubmit={handleSubmit}>
      <NotePreviewHeader authors={[author]} />
      <ValidatedTextField
        placeholder="Tape ta note ici... (tu peux aller à la ligne si besoin)"
        autoFocus
        multiline
        initialValue=""
        validator={validateRequiredField}
        onChange={setContent}
      />
      <div
        css={css`
          margin-top: ${spacing[50]};
        `}
      >
        <Button variant="secondary" size="small" onClick={() => onCancel()}>
          Annuler
        </Button>
        <Button
          variant="primary"
          size="small"
          css={css`
            margin-left: ${spacing[50]};
          `}
          type="submit"
          disabled={!submitEnabled}
        >
          Enregistrer
        </Button>
      </div>
    </form>
  )
}

type EditNoteFormProps = {
  note: Note
  onCancel: () => void
  onSave: (noteId: string, newContent: string) => void
}

function EditNoteForm({ note, onCancel, onSave }: EditNoteFormProps): ReactElement {
  const session = useSession()
  const [content, setContent] = useState<string | null>(note.currentContent)

  const submitEnabled = !!content

  const noteId = note.noteId
  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      if (!submitEnabled) {
        return
      }
      onSave(noteId, content)
    },
    [submitEnabled, noteId, content, onSave],
  )

  assert(session.user && 'email' in session.user, 'This feature is for orus agents only, and they all have an email')

  const userId = session.user.id
  const authors = note.authors.some((author) => author.userId === userId)
    ? note.authors
    : [
        ...note.authors,
        {
          userId: session.user.id,
          displayName: session.user.email,
        },
      ]

  return (
    <form onSubmit={handleSubmit}>
      <NoteHeader creationTimestamp={note.creationTimestamp} authors={authors} />
      <ValidatedTextField
        placeholder="Tape ta note ici... (tu peux aller à la ligne si besoin)"
        autoFocus
        multiline
        initialValue={note.currentContent || ''}
        validator={validateRequiredField}
        onChange={setContent}
      />
      <div
        css={css`
          margin-top: ${spacing[50]};
        `}
      >
        {' '}
        <Button variant="secondary" size="small" onClick={() => onCancel()}>
          Annuler
        </Button>
        <Button
          variant="primary"
          size="small"
          css={css`
            margin-left: ${spacing[50]};
          `}
          type="submit"
          disabled={!submitEnabled}
        >
          Enregistrer
        </Button>
      </div>
    </form>
  )
}

export type NotePreviewHeaderProps = {
  authors: NoteAuthor[]
}

export function NotePreviewHeader({ authors }: NotePreviewHeaderProps): ReactElement {
  const creationTimestamp = useCurrentTimestamp(oneMinute)
  return <NoteHeader creationTimestamp={creationTimestamp} authors={authors} />
}

export type NoteBlockProps = {
  note: Note
  onNoteUpdated: (noteId: string, newContent: string) => void
  onNoteDeleted: (note: Note) => void
}

export function NoteBlock({ note, onNoteDeleted, onNoteUpdated }: NoteBlockProps): ReactElement {
  const [formVisible, setFormVisible] = useState(false)

  if (formVisible) {
    return (
      <div
        css={css`
          position: relative;

          & .hover-button-bar {
            display: none;
          }

          &:hover {
            background-color: ${colorTokens['color-bg-warning-tertiary']};
          }

          &:hover .hover-button-bar {
            display: block;
          }
        `}
      >
        <EditNoteForm
          note={note}
          onCancel={() => setFormVisible(false)}
          onSave={(noteId, newContent) => {
            setFormVisible(false)
            onNoteUpdated(noteId, newContent)
          }}
        />
      </div>
    )
  }

  const deleteNote = () => {
    if (
      confirm(`Etes-vous sûr de vouloir supprimer la note de ${note.authors[0].displayName} "${note.currentContent}" ?`)
    ) {
      onNoteDeleted(note)
    }
  }

  return (
    <div
      css={css`
        position: relative;

        & .hover-button-bar {
          display: none;
        }

        &:hover {
          background-color: ${colorTokens['color-bg-warning-tertiary']};
        }

        &:hover .hover-button-bar {
          display: block;
        }
      `}
    >
      <NoteHeader creationTimestamp={note.creationTimestamp} authors={note.authors} />
      <Text
        css={css`
          white-space: pre-wrap;
          overflow-wrap: break-word;
          margin-top: ${spacing[70]};
        `}
      >
        {note.currentContent}
      </Text>
      <div
        className="hover-button-bar"
        css={css`
          position: absolute;
          top: 0;
          right: 0;
        `}
      >
        <Button variant="secondary" size="small" onClick={() => setFormVisible(true)} icon="pen-clip-solid">
          Modifier
        </Button>
        <Button
          variant="secondary"
          size="small"
          onClick={deleteNote}
          css={css`
            margin-left: ${spacing[50]};
          `}
          icon="trash-can-regular"
        >
          Supprimer
        </Button>
      </div>
    </div>
  )
}

export type NoteHeaderProps = {
  creationTimestamp: number
  authors: NoteAuthor[]
}

export function NoteHeader({ creationTimestamp, authors }: NoteHeaderProps): ReactElement {
  return (
    <Text
      variant="caption"
      css={css`
        margin-bottom: ${spacing[50]};
      `}
      element="h6"
    >
      {formatDateTime(creationTimestamp)} - <NoteAuthors authors={authors} />
    </Text>
  )
}

export type NoteAuthorsProps = {
  authors: NoteAuthor[]
}

export function NoteAuthors({ authors }: NoteAuthorsProps): ReactElement {
  switch (authors.length) {
    case 0:
      throw new TechnicalError('Unexpected empty author list')
    case 1:
      return <>{authors[0].displayName}</>
    case 2:
      return (
        <>
          {authors[0].displayName} (modifié par {authors[1].displayName})
        </>
      )
    default:
      return (
        <>
          {authors[0].displayName} (modifié par
          {authors
            .slice(1, authors.length - 1)
            .map((author) => author.displayName)
            .join(', ')}
          et
          {authors[authors.length - 1].displayName})
        </>
      )
  }
}
