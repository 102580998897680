import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { discountCodeLabels, type OrusDiscount } from '@orus.eu/dimensions'
import { Text } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardDiscountProps = {
  contract: BackofficeContractDescription
}

export const ContractCardDiscount: FunctionComponent<ContractCardDiscountProps> = memo(function ContractCardDiscount({
  contract,
}) {
  const text = getDiscountText(contract.discount)

  return (
    <DataRow>
      <Text variant="body2">Discount</Text>
      <Text variant="body2">{text}</Text>
    </DataRow>
  )
})

function getDiscountText(discount: OrusDiscount | null) {
  if (!discount || discount === 'none') {
    return 'Aucun'
  }
  if (discount === 'rcph-edge-case-10') {
    return 'Aucun pour RCPH (supprimé au renouvellement), 10% pour les autres produits'
  }
  if (discount === 'rcph-edge-case-20') {
    return 'Aucun pour RCPH (supprimé au renouvellement), 20% pour les autres produits'
  }
  return discountCodeLabels[discount.code]
}
